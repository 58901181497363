
import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Page from './componet/Home/Home';
import Therapieangebote from './componet/Therapieangebote/Therapieangebote';
import Footer from './componet/Footer/Footer';
import HeadMenu from './componet/Header/Head';
import Impressum from './componet/Impressum/Impressum';
// import './componet/main.css';
import './componet/Style.sass'


function App() {
  return (
  
    <>
      <BrowserRouter> 
   
      <HeadMenu/>
      <Routes>
          <Route path="/" element={<Page/>}></Route>
          <Route path="/Therapieangebote" element={<Therapieangebote/>}></Route>
          <Route path="/Impressum" element={<Impressum/>}></Route>
       </Routes>
       <Footer/>
       </BrowserRouter>
      </>
     
 
    
  );
}

export default App;
