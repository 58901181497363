import React from 'react';
import {Container} from 'react-bootstrap';
import image1 from '../../Assect/bg1.png';


const Banner = () => {

    return (

        <div className="banner-container">
            <img
                src={image1}
                alt="Banner"
                className="img-fluid banner-image"
            />
            <Container>
                <div className="banner-text">

                    <p>Beratung, Diagnostik und Behandlung bei Sprach-, Sprech-, Stimm- und Schluckstörungen.</p>
                </div>
            </Container>

        </div>


    );

}

export default Banner;
