import React from 'react';
import {Col, Row} from 'react-bootstrap';
import pic3 from '../../Assect/pic3.jpg';

function Card3() {
    return (

        <container-fluid>
            <Row className="justify-content-md-center Custom-row-card Card-custom">

                <Col sm={12} md={4}><img alt={''} className="img-fluid pe-4" src={pic3}/></Col>
                <Col sm={12} md={8}>
                    <div className='Page2-innerBox'>
                        <h1>Therapie von Erwachsenen</h1>

                        <p>Unser wichtigstes Mittel, um mit unseren Mitmenschen in Kontakt zu treten, ist die Sprache.
                            Sie ermöglicht uns, unsere Gedanken anderen
                            Menschen mitzuteilen und ist deshalb von
                            zentraler Bedeutung.</p>

                        <p>Durch neurologische Erkrankungen wie z.B.
                            Schlaganfall, Morbus Parkinson, Schädel-Hirn-Traumen oder operative Eingriffe im Mund- und
                            Kehlkopfbereich, kann es zur Beeinträchtigung
                            oder auch zum Verlust der Sprach- bzw. Sprechfähigkeit kommen.</p>

                        <p>Dies führt nicht selten zur sozialen Isolation
                            des Patienten. Sprachtherapeutische Behandlungsmethoden können helfen,
                            die Kommunikationsfähigkeit ganz oder teilweise wiederherzustellen,
                            um somit die Grenze zur Außenwelt zu durchbrechen.</p>

                    </div>
                </Col>


            </Row>
        </container-fluid>

    );
}

export default Card3;