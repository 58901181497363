import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../../Assect/logo.jpeg';

function HeadMenu() {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">

            <div className="container">
                <a className="navbar-brand" href="/">
                    <img
                        src={logo}
                        alt="Logo"
                        style={{height: '70px', width: 'auto'}}></img>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav  mb-2 mb-lg-0 ms-auto">
                        <li className="nav-item  ">
                            <Link className="nav-link right" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="Therapieangebote">Therapieangebote </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    );
}

export default HeadMenu;