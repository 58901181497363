import React from 'react';
import Dashboard from '../Dashboard/Dashboard';
import Banner from '../Banner/Banner';


function Page() {
    return (
        <>
            <Banner/>
            <Dashboard></Dashboard>
        </>
    );
}

export default Page;