import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import contactpic1 from '../../Assect/contactpic1.png';

export default class Dashboard extends Component {
    render() {

        return (
            <>
                <container-fluid>
                    <Row className="justify-content-md  Custom-row-card  bg-Contact">
                        <Col className='ps-md-5 text-center text-sm-start' md={12}>
                            <h2>Unser Team berät sie gern in einer unserer Praxen</h2>
                        </Col>

                        <Row className="justify-content-md  Custom-row-card">
                            <Col className='g-4 text-center text-sm-start ps-md-5' md={4} sm={6}>
                                <div className='bg-Contact-innerBox'>
                                    <h4>Praxis Bad Bibra</h4>

                                    <p className="card-text">
                                        Am Schmiedeweg 14<br/>
                                        06647 Bad Bibra<br/>
                                        Tel: <a href="tel:+493446570640">+49 34465 70640</a><br/>
                                        Fax: +49 34465 70641
                                    </p>
                                </div>

                            </Col>
                            <Col className='g-4 text-center text-sm-start' md={4} sm={6}>
                                <div className='bg-Contact-innerBox'>
                                    <h4>Praxis Eckartsberga</h4>

                                    <p className="card-text">
                                        Neue Str. 110<br/>
                                        06648 Eckartsberga<br/>
                                        Tel: <a href="tel:+493446740600">+49 34467 40600</a>
                                    </p>
                                </div>
                            </Col>
                            <Col className='g-4 d-none d-md-block text-center text-sm-start' md={4}>
                                <div className='bg-Contact-innerBox'>
                                    <img alt={''} className='img-fluid' src={contactpic1}/>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </container-fluid>


            </>
        )
    }
}
