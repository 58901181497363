import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import cardpic1 from '../../Assect/cardpic1.png';
import cardpic2 from '../../Assect/cardpic2.png';
import cardpic3 from '../../Assect/cardpic3.png';
import cardpic4 from '../../Assect/cardpic4.png';
import {Col, Row} from 'react-bootstrap';

function Card1() {
    return (
        <>

            <Row className='Card-custom mt-4'>
                <h1>Therapie im Überblick</h1>
                <CardGroup>

                    <Col xs={12} lg={3} sm={6}>
                        <Card className='Card-Box'>
                            <Card.Img variant="top" src={cardpic1}/>
                            <Card.Body className='Card-Box-Body'>
                                <Card.Title>
                                    <center>SPRACHTHERAPIE</center>
                                </Card.Title>
                                <Card.Text>
                                    <ul>
                                        <li>Sprachentwicklungsstörungen</li>
                                        <li>Sprachentwicklungsverzögerungen</li>
                                        <li>Eingeschränkter passiver und aktiver Wortschatz</li>
                                        <li>Dysgrammatismustherapie</li>
                                        <li>Aphasie</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>

                        </Card>
                    </Col>
                    <Col xs={12} lg={3} sm={6}>
                        <Card className='Card-Box'>
                            <Card.Img variant="top" src={cardpic2}/>
                            <Card.Body className='Card-Box-Body'>
                                <Card.Title>
                                    <center>SPRECHTHERAPIE</center>
                                </Card.Title>
                                <Card.Text>
                                    <ul>
                                        <li>Dyslalie</li>
                                        <li>Stottern</li>
                                        <li>Poltern</li>
                                        <li>Dysarthrie</li>
                                        <li>Sprechapraxie</li>
                                        <li>Näseln</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>

                        </Card>
                    </Col>
                    <Col xs={12} lg={3} sm={6}>
                        <Card className='Card-Box'>
                            <Card.Img variant="top" src={cardpic3}/>
                            <Card.Body className='Card-Box-Body'>
                                <Card.Title>
                                    <center>STIMMMTHERAPIE</center>
                                </Card.Title>
                                <Card.Text>
                                    <ul>
                                        <li>funktionelle, organische, entwicklungsbedingte und psychogene
                                            Stimmstörungen
                                        </li>
                                        <li>Erkrankungen z.B.: Parkinson, ALS, Multiple Sklerose</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>

                        </Card>
                    </Col>
                    <Col xs={12} lg={3} sm={6}>
                        <Card className='Card-Box'>
                            <Card.Img variant="top" src={cardpic4}/>
                            <Card.Body className='Card-Box-Body'>
                                <Card.Title>
                                    <center>WEITERHIN</center>
                                </Card.Title>
                                <Card.Text>
                                    <ul>
                                        <li>Behandlung von funktionell, organisch und neurologisch verursachten
                                            Schluckstörungen
                                        </li>
                                        <li>Therapie von myofunktionellen Störungen</li>
                                        <li>Therapie von angeborenen und erworbenen Hörstörungen</li>
                                        <li>Artikulationstraining</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>

                        </Card>
                    </Col>
                </CardGroup>
            </Row>

        </>


    );
}

export default Card1;