import React from 'react';
import {Link} from 'react-router-dom';

function Footer() {
    return (
        <footer className="bg-dark text-white py-4">
            <div className="container">
                <div className="row">
                </div>
                <div className="text-center mt-4">
                    <p>&copy; 2024 Kerstin Schreiter-Wlodkowski
                        <Link className="nav-link"
                              to="Impressum">Impressum</Link></p>

                </div>
            </div>
        </footer>
    );
}

export default Footer;