import React from 'react';
import {Col, Row} from 'react-bootstrap';
import pic1 from '../../Assect/pic1.jpg';

function Card2() {
    return (

        <container-fluid>
            <Row className="justify-content-md-center Custom-row-card Card-custom">
                <Col sm={12} className={'d-sm-block d-md-none'}><img alt={''} className="img-fluid" src={pic1}/></Col>
                <Col sm={12} md={8}>
                    <div className='Page2-innerBox'>
                        <h1>Therapie von Kindern</h1>

                        <p>Ein wichtiger Teil der Gesamtentwicklung eines
                            Kindes ist die Sprachentwicklung. Sie steht im
                            engen Zusammenhang mit seiner Motorik, der
                            Wahrnehmung und der Kognition.</p>

                        <p>Die Sprache des Kindes mit ihren Teilbereichen
                            Artikulation, Wortschatz und Grammatik kann sich
                            nur entfalten, wenn grundlegende Fähigkeiten
                            ausgebildet sind.</p>
                        <p>Dazu gehören u.a. das Sehen, das Hören,
                            die Feinmotorik, eine angemessene Hirnreifung,
                            ein gewisses Sprachverständnis, Motivation und
                            Freude am Sprechen.</p>
                    </div>
                </Col>
                <Col md={4} className={'d-none d-md-block'}><img alt={''} className="img-fluid" src={pic1}/></Col>

            </Row>
        </container-fluid>

    );
}

export default Card2;