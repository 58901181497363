import React from 'react';
import Card1 from './Card1';
import Card2 from './Card2';
import Card3 from './Card3';
import {Container} from 'react-bootstrap';

function Therapieangebote() {
    return (
        <>
            <Container>
                <Card1/><Card2/><Card3/>
            </Container>
        </>
    );
}

export default Therapieangebote;